import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

import tributoImg from '../../assets/images/tributos.png';
import pipaImg from '../../assets/images/pipa.png';
import eDocImg from '../../assets/images/edoc.png';
import rspImg from '../../assets/images/rsp.png';
import sigeiImg from '../../assets/images/sigei.png';
import sisgemImg from '../../assets/images/sisgem.png';
import contratosImg from '../../assets/images/contratos.png';
import socialImg from '../../assets/images/social.png';
import eventosImg from '../../assets/images/eventos.png';
import sigesImg from '../../assets/images/siges.jpg';
import obrasImg from '../../assets/images/obras.jpeg';
import patrimonioImg from '../../assets/images/patrimonio.png';
import sigedImg from '../../assets/images/siged.png';
import helpdeskImg from '../../assets/images/helpdesk.png';


const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Sistemas',
    paragraph: 'Atendemos a necessidade de todas as Secretarias dos Município, desenvolvendo soluções tecnologicas que melhora e facilita o trabalho de todos os colaboradores.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Finanças
                </div>
                <h3 className="mt-0 mb-12">
                  Tributos
                </h3>
                <p className="m-0">
                  Sistema de gerenciamento de arrecada≈¡o de tributos de feirantes, oferecendo mais controle na cobrança dos tributos.
                  O sistema possibilita o cadastro do feirante, registro do pagamento, emissão de comprovante de pagamento e relatório diários, mensais.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={tributoImg}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Infraestrutura
                </div>
                <h3 className="mt-0 mb-12">
                  e-Piranhas
                </h3>
                <p className="m-0">
                  Aplicativo desenvolvido pela Prefeitura Municipal de Piranhas, para que a população de Piranhas possa solicitar melhorias e reparos na sua cidade, como por exemplo, reparo nas vias, recolhimento de entulhos, reparo na iluminação, entre outros.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image style={{ width: 390 }}
                  src={"https://e.piranhas.al.gov.br/images/appIcon.png"}
                  alt="Features split 02"
                  width={411}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Agricultura
                </div>
                <h3 className="mt-0 mb-12">
                  PIPA
                </h3>
                <p className="m-0">
                  Sistema de gerenciamento de distribuição de carro Pipa. Possibilitando o cadastro das comunidades, cadastro dos pipeiras, relatórios de abastecimento, oferecendo um maior controle.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={pipaImg}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Administração
                </div>
                <h3 className="mt-0 mb-12">
                  e-Doc
                </h3>
                <p className="m-0">
                  Sistema para gestão em tramitação de documentos. O sistema oferece para todas as secretárias, um maior controle das tramitações dos documentos, registrando em tempo real, o envio e recebimento de todos os documentos.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={eDocImg}
                  alt="Features split 02"
                  width={411}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Administração
                </div>
                <h3 className="mt-0 mb-12">
                  RSP - Recadastramento
                </h3>
                <p className="m-0">
                  Sistema de Recadastramento do Servidor, armazenando digitalmente toda a documentação dos colaboradores.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={rspImg}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Administração
                </div>
                <h3 className="mt-0 mb-12">
                  Help Desk
                </h3>
                <p className="m-0">
                  Sistema de registro de incidentes e acompanhamento de demandas.
                  O Help Desk pode ser usado por todas as secretárias, ele permite registrar e controlar todos os incidentes,
                  permitindo um maior controle e apoio na tomada de decisões.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={helpdeskImg}
                  alt="Features split 02"
                  width={411}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Administração
                </div>
                <h3 className="mt-0 mb-12">
                  Patrimônio
                </h3>
                <p className="m-0">
                  Sistema de gestão patrimonial e bens de consumo. O sistema possibilita um maior controle vinculando o patrimônio ao setor, registrando com histórico toda a movimentação.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={patrimonioImg}
                  alt="Features split 02"
                  width={411}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Administração
                </div>
                <h3 className="mt-0 mb-12">
                  SISGEM
                </h3>
                <p className="m-0">
                  Sistema de Gestão Municipal. O SISGEM unifica as informações de todas as secretárias separando por modulos,
                  mostrando de maneira fácil e objetiva por meio de dashboards interativos que ajudam no acompanhamento da gestão municipal e nas tomadas
                  de decisões.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={sisgemImg}
                  alt="Features split 02"
                  width={411}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Administração
                </div>
                <h3 className="mt-0 mb-12">
                  CONTRATOS
                </h3>
                <p className="m-0">
                  Sistema de Gestão de Contratos. O sistema permite um maior controle de todos os contratos feitos pela prefeitura, com um Dashboard completo com gráficos e alertas facilitando o acompanhamento e tomada de decisões.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={contratosImg}
                  alt="Features split 02"
                  width={411}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Educação
                </div>
                <h3 className="mt-0 mb-12">
                  SIGED
                </h3>
                <p className="m-0">
                  Sistema integrado de gestão educacional. Controle e gestão pedagógica e administrativa para a rede de ensino.
                  Permite o registo de escolas e turmas, gestão pedagógica com controle de frequência, avaliações e registro de ocorrência dos alunos.
                  Na parte administrativa o sistema oferece controle dos transportes escolares, distorção de idade e matrícula online.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={sigedImg}
                  alt="Features split 02"
                  width={411}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Saúde
                </div>
                <h3 className="mt-0 mb-12">
                  SIGES
                </h3>
                <p className="m-0">
                  Sistema integrado de gestão de saúde. O SIGES permite o controle de medicamentos por cidadão e requisição,
                  controle de estoque integrando toda rede municipal com alertas para o abastecimento. Transferência de medicamentos entre a CAF para as farmácias com relatórios diários e mensais.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={sigesImg}
                  alt="Features split 02"
                  width={411}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Esporte e Eventos
                </div>
                <h3 className="mt-0 mb-12">
                  SIGEI
                </h3>
                <p className="m-0">
                  Sistema de Gerenciamento de Esportes Integrado. O SIGEI oferece cadastro dos times, ficha cadastral dos jogadores e comissões, histórico de times onde o jogador passou, histórico disciplinar, carteirinha do atleta e relatórios para auxiliar nas tomadas de decisões.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={sigeiImg}
                  alt="SIGEI"
                  width={411}
                  height={396} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Esporte e Eventos
                </div>
                <h3 className="mt-0 mb-12">
                  EVENTOS
                </h3>
                <p className="m-0">
                  Sistema de Gestão de Eventos. O sistema permite o cadastro dos participantes online, conferencia dos participantes na hora do eventos, disponibiliza todo cronograma do evento, controle de presença e relatórios.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={eventosImg}
                  alt="SIGEI"
                  width={411}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Administração
                </div>
                <h3 className="mt-0 mb-12">
                  OBRAS
                </h3>
                <p className="m-0">
                  Sistema de Gestão de Obras. O sistema faz o acompanhamento de todas as obras que o município realiza, oferecendo uma melhor gestão de tempo da realização da obra e valores.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={obrasImg}
                  alt="SIGEI"
                  width={411}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Secretária de Assistência Social
                </div>
                <h3 className="mt-0 mb-12">
                  SOCIAL
                </h3>
                <p className="m-0">
                  Sistema de Gestão de Assistência Social. O sistema vai permitir um maior controle de toda assistência para a população, através de relatórios o usuário vai ter controle de todos os beneficiados e das entregas, para que o beneficio chegue até a população sem atrasos.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={socialImg}
                  alt="SIGEI"
                  width={411}
                  height={396} />
              </div>
            </div>





          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;