import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import LogoImg from '../../../assets/images/LOGO-COLORIDA-ATUALIZADA.png';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="mt-2">
        <Link to="/">
          <Image
            src={LogoImg}
            alt="Piranhas"
            width={200}
            height={200}
          />
        </Link>
      </h1>
    </div>
  );
}

export { Logo };