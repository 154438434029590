import React from 'react';
// import sections
// import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Services from '../components/sections/Services';
import Banner from '../components/sections/Banner';
import DTI from '../components/sections/DTI';

const Home = () => {

  return (
    <>
      <DTI className="illustration-section-01" />
      {/* <FeaturesTiles /> */}
      <Services topDivider />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Banner split />
    </>
  );
}

export default Home;